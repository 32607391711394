import React, { useState } from 'react';
import Select from 'react-select';

// import IconArrowDown from '../assets/images/svg/arrow-down-header.inline.svg';

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '3px',
    border: '1px solid rgba(27,32,50,.1)',
    padding: '12px 16px',
  }),
  indicatorSeparator: () => ({}),
  placeholder: (provided) => ({ ...provided, color: '#8f93a2', fontSize: 14 }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#c91c20',
  }),
};

const Dropdown = ({ name, options, placeholder, ...props }) => {
  return (
    <Select
      name={name}
      styles={customStyles}
      placeholder={placeholder}
      options={options}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#1b20321a',
          primary25: '#e6e6e6',
        },
      })}
      {...props}
    />
  );

  // const [dropdownOpen, setDropdownOpen] = useState(false);
  // return (
  //   <div
  //     className={`c-dropdown ${dropdownOpen ? 'c-dropdown--open' : ''}`}
  //     onClick={() => setDropdownOpen(!dropdownOpen)}
  //   >
  //     <p className="c-dropdown__placeholder">
  //       {placeholder} <IconArrowDown />
  //     </p>
  //     <ul className="c-dropdown__list">
  //       {dataArray.map((item) => {
  //         return (
  //           <li key={item.id} className="item">
  //             <button>{item.name}</button>
  //           </li>
  //         );
  //       })}
  //     </ul>
  //   </div>
  // );
};

export default Dropdown;
