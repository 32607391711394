import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { navigate } from 'gatsby';
import { GoogleMap, withScriptjs, withGoogleMap, Marker } from 'react-google-maps';
import countries from 'countries-list';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import Layout from '../../components/Layout';
import Dropdown from '../../components/Dropdown';

import { URL_ROUTES_EN } from '../../constants/routes';

import imgAdress1 from '../../assets/images/contact-us/address.svg';
import imgAdress2 from '../../assets/images/contact-us/Reception Hours_.svg';
import imgAdress3 from '../../assets/images/contact-us/phone-call.svg';
import imgAdress4 from '../../assets/images/contact-us/whatsapp-2.svg';
import imgAdress5 from '../../assets/images/contact-us/email-3.svg';

import '../../assets/styles/pages/contact-us-page.scss';

const Map = () => {
  return (
    <GoogleMap
      defaultOptions={{ styles: demoFancyMapStyles3 }}
      defaultZoom={17}
      defaultCenter={{ lat: 41.39459, lng: 2.16565 }}
    >
      <Marker position={{ lat: 41.39459, lng: 2.16565 }} />
    </GoogleMap>
  );
};

const WrappedMap = withScriptjs(withGoogleMap(Map));

const demoFancyMapStyles3 = [
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#ca2031',
      },
    ],
  },
  {
    featureType: 'administrative.province',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 65,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 51,
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 30,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 40,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        hue: '#ffff00',
      },
      {
        lightness: -25,
      },
      {
        saturation: -97,
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'on',
      },
      {
        lightness: -25,
      },
      {
        saturation: -100,
      },
    ],
  },
];

const countryCodes = Object.keys(countries.countries);
const countryNames = countryCodes.map((code) => countries.countries[code].name);
const countryNamesOptions = countryNames.sort().map((country) => ({
  value: country,
  label: country,
}));

const EMPTY_CONTACT_DATA = {
  firstName: '',
  lastName: '',
  userEmail: '',
  comments: '',
  nationality: '',
};

const FieldValidationErrorMsg = ({ field }) => <div className="error-val">{field}</div>;

const ContactUs = () => {
  const [formData, setFormData] = useState(EMPTY_CONTACT_DATA);
  const [isFormValid, setIsFormValid] = useState(true);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setIsFormValid(true);
    setFormData({ ...formData, [name]: value });
  };

  const handleCountry = (value) => {
    setIsFormValid(true);
    setFormData({ ...formData, nationality: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formData.nationality === '') {
      setIsFormValid(false);
      return;
    }

    await fetch('/api/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...formData,
        userFromGoogleAds: window?.userFromGoogleAds,
        userFromFacebookAds: window?.userFromFacebookAds,
      }),
    });

    const MailchimpData = {
      FNAME: formData.firstName,
      LNAME: formData.lastName,
      MMERGE4: new Date().toISOString().split('T')[0], // contact date
      MMERGE17: 'Contact form', // Source
      MERGE25: formData.comments,
    };
    addToMailchimp(formData.userEmail, MailchimpData);

    setFormData(EMPTY_CONTACT_DATA);

    navigate(URL_ROUTES_EN.CONTACT_US_THANK_YOU);
  };

  return (
    <Layout
      title={'Contact Iberia Language Academy | Contact Details & Address'}
      description={
        "Send us a message and we'll reply within 24 hours. Or get in touch using our phone or WhatsApp, or address."
      }
    >
      <div className="contact-us-page">
        <section className="c-first-section c-first-section--md">
          <div className="container">
            <h1 className="title">Get in Touch!</h1>
            <p className="description">Contact us and we’ll reply within 24 hours.</p>
          </div>
        </section>

        <section className="second-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-7">
                <form onSubmit={handleSubmit} className="form-wrapper">
                  <h1 className="c-title-22 form__title">Send us a message</h1>
                  <label className="form__first-name">
                    <p className="c-text-14 text-under">
                      <FormattedMessage id="contacto.sec2.firstName" />
                    </p>
                    <input
                      value={formData.firstName}
                      onChange={handleInputChange}
                      name="firstName"
                      className="input__first-name"
                      type="text"
                      placeholder="John"
                      required
                    />
                  </label>
                  <label className="form__last-name">
                    <p className="c-text-14 text-under">
                      <FormattedMessage id="contacto.sec2.lastName" />
                    </p>
                    <input
                      value={formData.lastName}
                      onChange={handleInputChange}
                      name="lastName"
                      className="input__last-name"
                      type="text"
                      placeholder="Smith"
                      required
                    />
                  </label>
                  <label className="form__email">
                    <p className="c-text-14 text-under">
                      <FormattedMessage id="contacto.sec2.email" />
                    </p>
                    <input
                      value={formData.userEmail}
                      onChange={handleInputChange}
                      name="userEmail"
                      className="input__email"
                      type="email"
                      placeholder="johnsmith@gmail.com"
                      required
                    />
                  </label>
                  <label className="form__email">
                    <p className="c-text-14 text-under">Nationality*</p>
                    <Dropdown
                      // value={formData.nationality}
                      onChange={({ value }) => handleCountry(value)}
                      options={countryNamesOptions}
                      placeholder="Select your country"
                      name="userNationality"
                      isSearchable
                      required
                    />
                    {!isFormValid && <FieldValidationErrorMsg field={'Nationality is required'} />}
                  </label>
                  <label className="form__textarea">
                    <p className="c-text-14 text-under">
                      <FormattedMessage id="contacto.sec2.comment" />
                    </p>
                    <textarea
                      value={formData.comments}
                      onChange={handleInputChange}
                      name="comments"
                      className="input__textarea"
                      id=""
                      placeholder="Write your message…"
                    />
                  </label>
                  <button type="submit" className="c-btn c-btn--red">
                    <FormattedMessage id="contacto.sec2.submit" />
                  </button>
                </form>
              </div>

              <div className="col-lg-6 col-md-5">
                <div className="right">
                  <p className="c-text-18 description second-section__l">
                    Iberia Language Academy is centrally located in the Eixample neighbourhood, only
                    5 minutes walking from the Sagrada Familia and 15 minutes to Passeig de Gracia.
                  </p>
                  <div className="wrapper">
                    <div className="benefit-group">
                      <div className="group__img">
                        <img src={imgAdress1} alt="ILA" />
                      </div>
                      <div className="group__text">
                        <h3 className="c-title-18">Address:</h3>
                        <p className="c-text-14">
                          Carrer d’Aragó 405, entresuelo 1º
                          <br />
                          Barcelona, 08013
                          <br /> Spain
                        </p>
                      </div>
                    </div>

                    <div className="benefit-group">
                      <div className="group__img">
                        <img src={imgAdress2} alt="09.00 - 17.00, Monday to Friday" />
                      </div>
                      <div className="group__text">
                        <h3 className="c-title-18">
                          <FormattedMessage id="contacto.sec2.hours" />
                        </h3>
                        <p className="c-text-14">09.30-17.30, Monday to Friday</p>
                      </div>
                    </div>
                    <a href="tel:+34644290822" className="benefit-group">
                      <div className="group__img">
                        <img src={imgAdress4} alt="+34644499949" />
                      </div>
                      <div className="group__text">
                        <h3 className="c-title-18">Phone & Whatsapp:</h3>
                        <p className="c-text-14">(+34) 932 494 676</p>
                        <p className="c-text-14">(+34) 644 290 822 (Whatsapp)</p>
                      </div>
                    </a>
                    <a href="mailto:classes@ilabarcelona.com" className="benefit-group">
                      <div className="group__img">
                        <img src={imgAdress5} alt="classes@ilabarcelona.com" />
                      </div>
                      <div className="group__text">
                        <h3 className="c-title-18">
                          <FormattedMessage id="contacto.sec2.email" />
                        </h3>
                        <p className="c-text-14">classes@ilabarcelona.com</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="third-section google-map-section">
          <WrappedMap
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBfMKb2czX8NxQxofnL7b6tJs9soJ18apQ&callback=initMap&libraries=&v=weekly"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `449px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </section>
      </div>
    </Layout>
  );
};

export default ContactUs;
